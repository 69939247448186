<template>
  <div
    class="activity-container p-6 bg-white flex items-center justify-between" @click="handleView"
  >
    <div class="flex" style="width: 100%">
      <div class="sort font-bold mr-6">{{ pageCount + index + 1 }}</div>
      <div class="activity-info">
        <div style="display: flex; flex-direction: row; width: 100%">
          <p
            class="activity-name text-base font-bold mb-4"
            style="
              flex: 1;
              width: 100%;
              padding-right: 15px;
              box-sizing: border-box;
            "
          >
            {{ info.name }}
          </p>
          <div
            style="width: 24px; height: 24px; cursor: pointer"
            @click.stop="changeStarStatus"
          >
            <img
              v-if="info.star_status == 'no'"
              src="../assets/image/icon_star.png"
              class="star-img"
            />
            <img
              v-else
              src="../assets/image/icon_starred.png"
              class="star-img"
            />
          </div>
        </div>
        <div style="display: flex; width: 100%; align-items: center">
          <div
            style="
              flex: 1;
              width: 100%;
              padding-right: 10px;
              box-sizing: border-box;
            "
          >
            <div class="activity-time flex text-sm font-bold mb-4">
              <p><span>Category：</span>{{ info.type ? info.type.join(","): '' }}</p>
              <!-- <p><span>Organization：</span>{{ info.org }}</p> -->
            </div>
            <!-- <div class="activity-desc mb-4">
              <p class="text-sm">
                {{ info.intro }}
              </p>
            </div> -->
          </div>
          <!-- <el-button type="primary" class="mr-2" @click="handleView"
            >View</el-button
          > -->
        </div>
        <div style="" class="flex">
          <div v-for="(tag, index) in info.tags" :key="index">
            <p class="common-tag" style="margin-right: 15px">{{ tag }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Activity",
  props: {
    info: Object,
    index: Number,
    pageCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleView() {
      this.$emit("view");
    },
    changeStarStatus() {
      this.$emit("changeStarStatus");
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-container {
  cursor: pointer;
  height: 140px;
  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }
  .activity-info {
    width: 100%;
    .activity-name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: #333333;
    }
    .activity-time {
      color: #ff8900;
      // span {
      //   color: #999999;
      // }
      p {
        margin-right: 45px;
      }
    }
    .activity-location {
      color: #ff8900;
      span {
        color: #999999;
      }
      p {
        margin-right: 60px;
      }
    }
    .activity-desc {
      color: #999999;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 40px;
    }
    .star-img {
      width: 18px;
      height: 18px;
      object-fit: cover;
    }
  }
}
</style>
