<template>
  <div style="margin: 30px" class="bg-white p-6">
    <el-page-header
      @back="goBack"
      content="Activity Details"
      title="back"
      style="margin-left: 10px"
    >
    </el-page-header>
    <div style="margin: 10px">
      <el-form v-model="optionInfo" label-position="top">
        <el-form-item label="Activity Name">
          <p class="activity-name text-base font-bold mb-4">
            {{ optionInfo.name }}
          </p>
        </el-form-item>
        <el-form-item label="Type">
          <div>
            <el-tag
              v-for="(type, index) in types"
              :key="index"
              class="mr-3 mb-2"
              >{{ type }}
            </el-tag>
          </div>
          <!-- <p class="activity-name text-base font-bold mb-4">
            {{ optionInfo.type }}
          </p> -->
        </el-form-item>
        <!-- <el-form-item label="Organization">
          <p class="activity-name text-base font-bold mb-4">
            {{ optionInfo.org }}
          </p>
        </el-form-item> -->
        <el-form-item label="Introduction">
          <p
            class="activity-name text-base font-bold mb-4"
            style="line-height: 1.8rem"
          >
            {{ optionInfo.intro }}
          </p>
        </el-form-item>
        <el-form-item
          label="Tags"
          v-if="optionInfo.tags && optionInfo.tags.length > 0"
        >
          <div>
            <el-tag
              v-for="(tag, index) in optionInfo.tags"
              :key="index"
              class="mr-3 mb-2"
              >{{ tag }}
            </el-tag>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getActivityOption } from "../../api/eae";
import ActivityIdea from "../../components/ActivityIdea.vue";

export default {
  name: "ActivityOptionView",
  components: {
    ActivityIdea,
  },
  data() {
    return {
      id: this.$route.query.id,
      optionInfo: {
        id: "",
        type: "",
        name: "",
        org: "",
        intro: "",
      },
      types: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getActivityOption(this.id).then((res) => {
        this.optionInfo = res.data.data;
        this.formatOptionTypes(this.optionInfo.type);
      });
    },
    formatOptionTypes(type) {
      this.types = [];
      if (type && type != null && type != "") {
        type.forEach((item) => {
          let temp = item.split("&");
          for (let i = 0; i < temp.length; i++) {
            this.types.push(temp[i]);
          }
        });
      }
    },
    goBack() {
      this.$router.push({
        path: "/home/activityOptions",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-idea {
  width: calc(50% - 20px);
  margin: 16px 10px;
}

.activity-name {
  color: #333333;
}

/deep/ .el-form--label-top .el-form-item__label {
  padding: 0px 0px 3px 0px;
}
</style>
